import { Logs } from "expo";

Logs.enableExpoCliLogging();

import React, { useState } from "react";
import { Amplify } from "aws-amplify";
import awsExports from "./src/aws-exports";
Amplify.configure(awsExports);

//import { Button, View, TextInput, StyleSheet, Text } from "react-native";
//import { Auth } from "aws-amplify";

import { Platform } from "react-native";
import { withAuthenticator, Button, Heading } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
// let withAuthenticator;

// if (Platform.OS === "android" || Platform.OS === "ios") {
//   withAuthenticator = require("@aws-amplify/ui-react-native").withAuthenticator;
// } else {
//   withAuthenticator = require("@aws-amplify/ui-react").withAuthenticator;
// }

function App({ signOut, user }) {
  const platform = Platform.OS;
  const test_word = "Welcome!";

  // async function signOut() {
  //   try {
  //     console.log("signing out");
  //     await Auth.signOut();
  //   } catch (error) {
  //     console.log("error signing out: ", error);
  //   }
  // }

  return (
    <div style={styles.container}>
      <Heading level={1}>Hello {user.username}</Heading>
      <Button onClick={signOut} style={styles.button}>
        Sign out
      </Button>
    </div>
  );
}

const styles = {
  container: {
    width: 400,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  },
  todo: { marginBottom: 15 },
  input: {
    border: "none",
    backgroundColor: "#ddd",
    marginBottom: 10,
    padding: 8,
    fontSize: 18,
  },
  todoName: { fontSize: 20, fontWeight: "bold" },
  todoDescription: { marginBottom: 0 },
  button: {
    backgroundColor: "black",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
  },
};

export default withAuthenticator(App);
